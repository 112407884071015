import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Card } from "../../../components/card";
import { PaginationSchema, usePaginationStore } from "../../../store/pagePaginationStore";
import { useState } from "react";
import { buildUrl } from "../../../util/buildUrl";
import useSWRImmutable from "swr/immutable";
import { defaultGetFetcher } from "../../../api/base";
import { z, ZodError } from "zod";
import { EmptyTableData, TableDataHeader, TableDataRows, TableError, TableLoading } from "./LeadRequestTableHelpers";
import { TablePagination } from "../../../components/tables/tablePagination";

const LeadRequestSchema = z.object({
  id: z.number(),
  created: z.string().datetime(),
  email: z.string().nullable(),
  mixpanelAnonUserId: z.string(),
  status: z.string(),
});

const LeadRequestListSchema = z.object({
  leads: LeadRequestSchema.array(),
  pagination: z.object(PaginationSchema.shape),
});

export type LeadRequest = z.infer<typeof LeadRequestSchema>;
export type LeadRequestList = z.infer<typeof LeadRequestListSchema>;

const LeadRequestsTable = () => {
  const currentPage = usePaginationStore((state) => state.currentPage);
  const setPaginationData = usePaginationStore.getState().updatePaginationInfo;
  const [searchQuery, _] = useState<string | null>(null);

  const url = buildUrl("/api/v2/admin/advertising/lead", {
    pageNumber: currentPage,
    pageSize: 10,
    searchQuery: searchQuery,
  });
  const { data, isLoading, error } = useSWRImmutable<string, Error>(url, (url: string) => defaultGetFetcher(url), {
    revalidateOnMount: true,
  });

  const getTableData = () => {
    if (isLoading) return <TableLoading />;
    if (error) return <TableError errorMessage={error.message} />;
    try {
      const responseData: LeadRequestList = LeadRequestListSchema.parse(data!);
      setTimeout(() => setPaginationData(responseData.pagination));
      if (responseData.leads.length == 0) return <EmptyTableData />;

      return <TableDataRows leads={responseData.leads} />;
    } catch (parseError: unknown) {
      if (parseError instanceof ZodError) return <TableError errorMessage={parseError.message} />;
      else return <TableError errorMessage={String(parseError)} />;
    }
  };

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Lead requests</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <div className="relative">
            <div className="relative bg-white">
              <div className="flex flex-col items-center justify-between p-4 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2 ">
                  <form className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon className="h-4 w-4 text-secondary-500" />
                      </div>
                      <input
                        type="text"
                        disabled
                        id="simple-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 placeholder:italic placeholder:text-gray-300"
                        placeholder="Search (disabled)"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="w-full h-full overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <TableDataHeader />
                <tbody className="">{getTableData()}</tbody>
              </table>
            </div>
            <TablePagination />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LeadRequestsTable;
