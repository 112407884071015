import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { LeadRequest } from "./LeadRequestsList";
import { getDjangoAppUrl } from "../../../api/base";

export const TableLoading = () => {
  return Array.from({ length: 10 }, (_, index) => {
    return (
      <tr key={index}>
        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </th>
        <td className="px-6 py-4">
          <div className="px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
        <td className="px-6 py-4">
          <div className="px-3 py-1 w-1/2 mx-auto text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full animate-pulse "></div>
        </td>
      </tr>
    );
  });
};

export const TableError = ({ errorMessage }: { errorMessage: string }) => {
  return [
    <tr key={"error"}>
      <th scope="row" colSpan={4} rowSpan={10} className="px-6 py-4 font-medium text-red-900 whitespace-nowrap ">
        <span className="flex gap-x-2 justify-center items-center">
          <ExclamationTriangleIcon className="w-4 h-4 text-red-600 font-bold" />
          {errorMessage}
        </span>
      </th>
    </tr>,
    ...Array.from({ length: 9 }, (_, index) => {
      return (
        <tr key={index} className="px-6 py-4">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "></th>
        </tr>
      );
    }),
  ];
};

export const EmptyTableData = () => {
  return [
    <tr key={"empty"}>
      <th scope="row" colSpan={4} rowSpan={10} className="px-6 py-4 font-medium  whitespace-nowrap ">
        <span className="flex gap-x-2 justify-center items-center">No data available</span>
      </th>
    </tr>,
    ...Array.from({ length: 9 }, (_, index) => {
      return (
        <tr key={index} className="px-6 py-4">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "></th>
        </tr>
      );
    }),
  ];
};

export const TableDataRows = ({ leads }: { leads: LeadRequest[] }) => {
  return (
    <>
      {leads.map((c) => (
        <tr className="bg-white border-b" key={c.id}>
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
            <Link
              to={`/lead-requests/${c.id}/details`}
              className="block mx-auto text-primary-700 hover:text-primary-900 font-medium"
            >
              {c.mixpanelAnonUserId}
            </Link>
          </th>
          <td className="px-4 py-2 whitespace-nowra max-w-[200px] truncate text-center">{c.email}</td>
          <td className="px-4 py-2 whitespace-nowrap">{new Date(c.created).toLocaleString()}</td>
          <td className="px-4 py-2">{c.status.toUpperCase()}</td>
          <td className="px-4 py-2 whitespace-nowrap">
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={getDjangoAppUrl(`admin/advertising/leadrequestforsessionwithadvisor_liteadminproxy/${c.id}/change/`)}
              className="block mx-auto text-white text-center text-xs bg-primary-700 hover:bg-primary-800
              focus:ring-4 focus:ring-primary-300 font-medium rounded-lg py-1 focus:outline-none"
            >
              View in Django
            </Link>
          </td>
        </tr>
      ))}
    </>
  );
};

export const TableDataHeader = () => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 w-full">
      <tr className="w-full">
        <th scope="col" className="px-6 py-3 whitespace-nowrap ">
          Mixpanel ID
        </th>
        <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
          Email
        </th>
        <th scope="col" className="px-6 py-3 whitespace-nowrap ">
          Created on
        </th>
        <th scope="col" className="px-6 py-3 whitespace-nowrap ">
          Status
        </th>
        <th scope="col" className="px-6 py-3 whitespace-nowrap ">
          Tools
        </th>
      </tr>
    </thead>
  );
};
